import {ErrorObject} from 'ajv'
import {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios'

export interface StringParams {
  [s: string]: string
}

//
// Token Handling
//

export interface PersistedToken {
  idToken: string
  accessToken: string
  refreshToken: string
}

export interface AuthenticatorResponse {
  /**
   * Exact info of the user name entered by the user. Effectively a duplicate:
   * Not useful for us as the same data is stored in the token itself.
   */
  username?: string

  /**
   * CSRF protection hash. Randomly generated and verified when the user
   * is returning to the application. Mostly relevant for Web only as we
   * have to make sure that the user is really returning after being
   * redirected from us before accepting the incoming authorization values.
   */
  state?: string

  /**
   * We only support `Bearer` types. This value is never stored as we do
   * not need it for any of the follow-up requests.
   */
  token_type?: 'Bearer'

  /**
   * Incoming relative expires field in seconds. This is a duplicate for the
   * UTC `exp` field in the actual token. We do not use this value.
   */
  expires_in?: number

  /**
   * Incoming access token. Will be renamed to `accessToken` before storing it.
   */
  access_token?: string

  /**
   * Incoming refresh token. Will be renamed to `refreshToken` before storing it.
   */
  refresh_token?: string

  /**
   * Incoming id token. Will be renamed to `idToken` before storing it.
   */
  id_token?: string
}

export interface RefreshTokenRequest {
  refresh_token: string
  grant_type: 'refresh_token'
}

export interface RevokeRefreshTokenRequest {
  client_id: string
  token_type_hint: 'refresh_token'
  token: string
}

//
// Login Flow
//

export interface LoginFlowState {
  state: string
  codeVerifier: string
  href: string
}

export interface LoginStorage {
  setToken: (token: PersistedToken) => void
  getToken: () => PersistedToken
  resetToken: () => void
  setFlow: (flow: LoginFlowState) => void
  getFlow: () => LoginFlowState
  resetFlow: () => void
}

export type SignInRequest = {
  url: string
  flow: LoginFlowState
}

export type SignOutRequest = {
  url: string
}

export interface AuthRequestProvider {
  getClientId: () => string
  getAuthSuccessRedirectUrl: () => string
  createRegistrationRequest(
    authenticatorUrl: string,
    registrationType: 'create-account' | 'request-access',
    countryId: string,
    clientId?: string
  ): Promise<SignInRequest>
  createSignInRequest(
    authenticatorUrl: string,
    extraAuthenticatorQueryParams: {[key: string]: string},
    clientId?: string
  ): Promise<SignInRequest>
  createSignOutRequest(endSessionUrl: string, storage: LoginStorage): SignOutRequest
}

export type DecodedToken = {
  user_id: string
  country_code: string
  given_name: string
  email: string
  isTestUser: string
  name: string
}

export type LoginResponse =
  | {
      loggedIn: false
    }
  | {
      loggedIn: true
      decodedToken: DecodedToken
    }
  | {
      loggedIn: true
      decodedToken: DecodedToken
      token: PersistedToken
    }

export type OpenIdConfig = {
  issuer: string
  jwks_uri: string
  authorization_endpoint: string
  token_endpoint: string
  userinfo_endpoint: string
  end_session_endpoint: string
  check_session_iframe: string
  revocation_endpoint: string
  introspection_endpoint: string
  device_authorization_endpoint: string
  frontchannel_logout_supported: boolean
  frontchannel_logout_session_supported: boolean
  backchannel_logout_supported: boolean
  backchannel_logout_session_supported: boolean
  scopes_supported: string[]
  claims_supported: string[]
  grant_types_supported: string[]
  response_types_supported: string[]
  response_modes_supported: string[]
  token_endpoint_auth_methods_supported: string[]
  id_token_signing_alg_values_supported: string[]
  subject_types_supported: string[]
  code_challenge_methods_supported: string[]
  request_parameter_supported: boolean
}

export interface LoginFlow {
  startLogoutProcess: () => Promise<void>
  startLoginProcess: (path?: string) => Promise<void>
  startRegistrationProcess(
    registrationType: 'create-account' | 'request-access',
    countryId?: string
  ): Promise<void>
  getLoginState: () => Promise<LoginResponse>
  fetchOpenIdConfig: () => Promise<AxiosResponse<OpenIdConfig | undefined>>
}

export interface BackendConfig {
  AUTH_URL: string
  API_URL: string
  MHC_URL: string
  [name: string]: string
}

export interface BackendConfigList {
  [name: string]: BackendConfig
}

export interface BackendSelector {
  getSelectedBackend: () => BackendConfig
}

//
// API Client
//

export interface PublicClientConfig {
  backendSelector: BackendSelector

  // TODO
  getTokenRefreshRequestHeaders?: () => any

  // Callbacks
  onResponseError?: (error: AxiosError) => void
  onValidateJsonError?: (errors: ErrorObject[], response: AxiosResponse) => void
  onRequest?: (request: AxiosRequestConfig) => void
  onResponse?: (response: AxiosResponse) => void
}

export type ClientConfig = PublicClientConfig & {
  authRequestProvider: AuthRequestProvider
  loginStorage: LoginStorage
  loginFlow: LoginFlow
}

export type BaseUrlOverride = {
  urlMatcher: RegExp
  baseUrl: string
}

export type TokenClientConfig = PublicClientConfig & {
  token: string
}

export interface FetchUsersParameters {
  companyName?: string
  country?: string
  name?: string
  eMail?: string
  mobileNumber?: string
  createdBy?: string
  isTester?: string
  isActive?: boolean
  isInternal?: boolean
  marketId?: string
  roleType?: string
  hasRoles?: boolean
  neverLoggedIn?: boolean
  lastActivityProduct?: string
  skip?: number
  limit?: number
  customerId?: string
  payerId?: string
  contractId?: string
  mhcSiteId?: string
  orgUnitId?: string
}

interface Value<T> {
  type: 'value'
  value: T
}

interface Err<E> {
  type: 'error'
  error: {errorCode: number; message: string}
}

export type Result<T, E> = Value<T> | Err<E>

export enum Product {
  OnSite = 'OnSite',
  Cockpit = 'Cockpit',
  Hub = 'Hub',
  TruckMonitor = 'TruckMonitor',
  HConnect = 'HConnect',
  UserManagement = 'UserManagement',
  HCEM = 'HCEM',
  HProduce = 'HProduce',
  Maintain = 'Maintain',
  POM = 'POM',
  Local = 'local',
  Datalink = 'Datalink',
  Invitation = 'Invitation',
  Settings = 'Settings',
  CRM = 'CRM',
  CarbonBank = 'CarbonBank',
  CSC = 'CSC'
}

export enum Channel {
  SMS = 'sms',
  EMAIL = 'email'
}

export type PermissionType =
  | 'None'
  | 'CREATE_DATALINK_DEVELOPER'
  | 'VIEW_ORDERS_DELIVERIES'
  | 'CHANGE_ORDERS'
  | 'VIEW_MATERIALTESTS'
  | 'VIEW_FINANCE'
  | 'CHANGE_DELIVERIES_APPROVALS'
  | 'VIEW_DELIVERIES_APPROVALS'
  | 'VIEW_DEMAND'
  | 'VIEW_INVOICES'
  | 'VIEW_ELECTRICITY_PRICE'
  | 'VIEW_MACHINE_PLAN'
  | 'CHANGE_MACHINE_PLAN'
  | 'CHANGE_ELECTRICITY_ORDER'
  | 'VIEW_PAYERS'
  | 'CLONE_USERS'
  | 'VIEW_SUBSCRIPTIONS'
  | 'CHANGE_INVOICES'
  | 'VIEW_MATERIALCERTIFICATES_SNIPPETS'
  | 'CHANGE_MATERIALCERTIFICATES_SNIPPETS'
  | 'DELETE_MATERIALCERTIFICATES_SNIPPETS'
  | 'VIEW_CONTENTS'
  | 'CHANGE_CONTENTS'
  | 'DELETE_CONTENTS'
  | 'VIEW_ROLE_REQUESTS'
  | 'VIEW_CREDIT'
  | 'VIEW_PLANT_EVENT'
  | 'CREATE_PLANT_EVENT'
  | 'VIEW_PLANT_TASK'
  | 'CREATE_PLANT_TASK'
  | 'CREATE_SHIFT_REPORT'
  | 'VIEW_SHIFT_REPORT'
  | 'VIEW_PREDICTIONS'
  | 'VIEW_QOUTES'
  | 'CHANGE_ORDERS_PLUSLOAD'
  | 'CHANGE_FINANCE'
  | 'EDIT_PLANT_EVENTS'
  | 'DELETE_PLANT_EVENTS'
  | 'CHANGE_PLANT_EVENT_STATUS'
  | 'FILTER_PLANT_EVENTS'
  | 'SEARCH_PLANT_EVENTS'
  | 'SEND_PLANT_EVENT_TO_SAP'
  | 'EDIT_PLANT_TASK'
  | 'DELETE_PLANT_TASKS'
  | 'ASSIGN_PLANT_TASKS'
  | 'REASSIGN_PLANT_TASKS'
  | 'VIEW_PLANT_EVENT_COMMENTS'
  | 'ADD_PLANT_EVENT_COMMENTS'
  | 'EDIT_PLANT_EVENT_COMMENTS'
  | 'DELETE_PLANT_EVENT_COMMENTS'
  | 'ADD_DOCUMENTS'
  | 'DELETE_DOCUMENTS'
  | 'DOWNLOAD_SHIFTREPORT'
  | 'VIEW_ROLES'
  | 'CHANGE_WHITE_AND_BLOCKLIST'
  | 'VIEW_PLANT_SENSOR_DATA'
  | 'ORDER_FIX_TIME'
  | 'CREATE_SAP_REQUESTS'
  | 'CREATE_ROOT_CAUSE_FAILURE_ANALYSES'
  | 'CONTRIBUTE_TO_ROOT_CAUSE_FAILURE_ANALYSES'
  | 'CHANGE_APP_ACCOUNTS'
  | 'CHANGE_QUOTES'
  | 'CHANGE_TESTS'
  | 'VIEW_ALL_DATA'
  | 'CHANGE_ALL_DATA'
  | 'VIEW_USERS'
  | 'INVITE_USERS'
  | 'DELETE_USERS'
  | 'CHANGE_USERS'
  | 'GRANT_USER_MANAGEMENT_RIGHTS'
  | 'GRANT_SUPERADMIN_RIGHTS'
  | 'VIEW_TESTS'
  | 'VIEW_CUSTOMERS'
  | 'VIEW_SITES'
  | 'VIEW_PROJECTS'
  | 'VIEW_DOCUMENTS'
  | 'VIEW_MATERIALCERTIFICATES'
  | 'ASSIGN_ROLES'
  | 'VIEW_PLANTS'
  | 'ACCESS_PRODUCT_HUB'
  | 'DELETE_GLOBAL_ADMIN'
  | 'VIEW_TRUCKS'
  | 'CHANGE_PERMISSIONS'
  | 'MANAGE_CONFIGURATION'
  | 'VIEW_CONFIGURATIONS'
  | 'CHANGE_CONFIGURATIONS'
  | 'BOARD_MEMBER'
  | 'VIEW_COMMENTS'
  | 'ADD_COMMENTS'
  | 'EDIT_COMMENTS'
  | 'DELETE_COMMENTS'
  | 'VIEW_MATERIALS'
  | 'CHANGE_MATERIALS'
  | 'VIEW_ASSETS'
  | 'CHANGE_ASSETS'
  | 'CHANGE_USER_LOGIN'
  | 'MANAGE_DATALINK_APPLICATIONS'
  | 'VIEW_DATALINK_API_PRODUCT'
  | 'VIEW_EQUIP_PULSE'
  | 'VIEW_MATERIAL_STORAGE'
  | 'CHANGE_MATERIAL_STORAGE'
  | 'VIEW_POM_DASHBOARD'
  | 'VIEW_KILN_MONITORING_DATA'
  | 'VIEW_AUDIT'
  | 'VIEW_FEATURES'
  | 'VIEW_UPM'
  | 'VIEW_MATERIAL_ORDERS'
  | 'CHANGE_MATERIAL_ORDERS'
  | 'CHANGE_FEATURES'
  | 'ASSIGN_MASTER_DATA_PROCESS'
  | 'CHANGE_MASTER_DATA_PROCESS'
  | 'MANAGE_COMPANIES'
  | 'MANAGE_MESSAGES'
  | 'VIEW_TRANSACTIONS'
  | 'VIEW_SAVINGS'
  | 'VIEW_CONTRACTS'
  | 'VIEW_CERTIFICATES'
  | 'MANAGE_SAVINGS'
  | 'MANAGE_TRANSACTIONS'
  | 'MANAGE_CERTIFICATES'
  | 'CREATE_AEMMA_ORDERS'
  | 'EDIT_AEMMA_ORDERS'
  | 'DELETE_AEMMA_ORDERS'
  | 'VIEW_AEMMA_PAYMENTS'
  | 'CREATE_AEMMA_PAYMENTS'
  | 'EDIT_AEMMA_PAYMENTS'
  | 'DELETE_AEMMA_PAYMENTS'
  | 'VIEW_AEMMA_LOCATIONS'
  | 'CREATE_AEMMA_LOCATIONS'
  | 'EDIT_AEMMA_LOCATIONS'
  | 'DELETE_AEMMA_LOCATIONS'
  | 'VIEW_AEMMA_TRANSPORTERS'
  | 'CREATE_AEMMA_TRANSPORTERS'
  | 'EDIT_AEMMA_TRANSPORTERS'
  | 'DELETE_AEMMA_TRANSPORTERS'
  | 'VIEW_AEMMA_CUSTOMER_MANAGEMENT'
  | 'CREATE_AEMMA_CUSTOMER_MANAGEMENT'
  | 'EDIT_AEMMA_CUSTOMER_MANAGEMENT'
  | 'DELETE_AEMMA_CUSTOMER_MANAGEMENT'
  | 'VIEW_AEMMA_BANKS'
  | 'CREATE_AEMMA_BANKS'
  | 'EDIT_AEMMA_BANKS'
  | 'DELETE_AEMMA_BANKS'
  | 'VIEW_AEMMA_SETTLEMENT_BANKS'
  | 'CREATE_AEMMA_SETTLEMENT_BANKS'
  | 'EDIT_AEMMA_SETTLEMENT_BANKS'
  | 'DELETE_AEMMA_SETTLEMENT_BANKS'
  | 'VIEW_AEMMA_PRODUCTS'
  | 'CREATE_AEMMA_PRODUCTS'
  | 'EDIT_AEMMA_PRODUCTS'
  | 'DELETE_AEMMA_PRODUCTS'
  | 'VIEW_AEMMA_DELIVERY_TRACKING'
  | 'CREATE_AEMMA_DELIVERY_TRACKING'
  | 'EDIT_AEMMA_DELIVERY_TRACKING'
  | 'DELETE_AEMMA_DELIVERY_TRACKING'
  | 'VIEW_AEMMA_TRUCK_TRACKING'
  | 'CREATE_AEMMA_TRUCK_TRACKING'
  | 'EDIT_AEMMA_TRUCK_TRACKING'
  | 'DELETE_AEMMA_TRUCK_TRACKING'
  | 'VIEW_AEMMA_MANUAL_PAYMENT_APPROVAL'
  | 'CREATE_AEMMA_MANUAL_PAYMENT_APPROVAL'
  | 'EDIT_AEMMA_MANUAL_PAYMENT_APPROVAL'
  | 'DELETE_AEMMA_MANUAL_PAYMENT_APPROVAL'
  | 'VIEW_AEMMA_USERS'
  | 'CREATE_AEMMA_USERS'
  | 'EDIT_AEMMA_USERS'
  | 'DELETE_AEMMA_USERS'
  | 'READ_JANUS_PLANT_DATA'
  | 'WRITE_JANUS_PLANT_DATA'
  | 'EDIT_JANUS_PLANT_DATA'
  | 'READ_JANUS_PLANT_CONFIG'
  | 'WRITE_JANUS_PLANT_CONFIG'
  | 'CREATE_MASTER_DATA'
  | 'VIEW_CHECKLISTS'
  | 'CHANGE_CHECKLISTS'
  | 'VIEW_RELIABILITY_ENGINEER_DASHBOARD'
  | 'MANAGE_HCONNECT_CONFIGURATIONS'
  | 'VIEW_PROCESS_ENGINEER_DASHBOARD'
  | 'VIEW_OPTIMIZATION_SPECIALIST_DASHBOARD'

export type RoleType =
  | 'ACCOUNTANT'
  | 'ACCOUNTANT_NOTESTS'
  | 'BUSINESS_OWNER'
  | 'CONTROLLER'
  | 'CUSTOMER_ADMIN'
  | 'DATALINK_ACCESSOR'
  | 'DATALINK_DEVELOPER'
  | 'DELIVERIES_APPROVER'
  | 'FINISHER'
  | 'ORDER_PLACER'
  | 'ORDER_PLACER_FINANCE'
  | 'ORDER_PLACER_FIXTIME'
  | 'PLANT_MANAGER'
  | 'QA_ENGINEER'
  | 'SUPERINTEDENT'
  | 'ANALYTICS'
  | 'BACKEND_SERVICE'
  | 'CONTROL_ROOM_OPERATOR'
  | 'CUSTOMER_SERVICE_CENTER'
  | 'DATALINK_ADMIN'
  | 'DISPATCHER'
  | 'ENERGY_MANAGER'
  | 'GLOBAL_ADMIN'
  | 'MAINTENANCE_MANAGER'
  | 'MANAGING_DIRECTOR'
  | 'PLANT_DIRECTOR'
  | 'PRODUCTION_SUPERVISOR'
  | 'QC_SUPERVISOR'
  | 'RELIABILITY_CONTRIBUTOR'
  | 'RELIABILITY_ENGINEER'
  | 'SALES_AGENT'
  | 'SAP_USER'
  | 'SERVICE_ACCOUNT'
  | 'SHIFT_LEADER'
  | 'SHIFT_REPORT_READ_ONLY'
  | 'SHIFT_WORKER'
  | 'SUPER_ADMIN'
  | 'SUPPORT_AGENT'
  | 'HPRODUCE_ADMIN'
  | 'FEATURES_ADMIN'
  | 'CARBON_BANK_ADMIN'
  | 'CARBON_BANK_ALLOCATION_ASSURER'
  | 'CARBON_BANK_CUSTOMER'
  | 'CARBON_BANK_SAVING_ASSURER'
  | 'QC_VIEWER'
  | 'AEMMA_ADMIN'
  | 'AEMMA_ACCOUNTS_OFFICER'
  | 'AEMMA_AUDIT'
  | 'AEMMA_CUSTOMER'
  | 'AEMMA_CUSTOMER_DELEGATE'
  | 'AEMMA_SALES_OFFICER'
  | 'AEMMA_TRANSPORTER'
  | 'CARBON_BANK_HM_ALLOCATION_ADMIN'
  | 'HCONNECT_CONFIGURATIONS_ADMIN'
  | 'RELIABILITY_ADMIN'

export type LastActivityDate = {
  clientId: Product
  lastActivityDate: string
}
